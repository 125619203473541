.detail-card {
    margin: 1rem 0rem;
    /* background-color: #ffffff11; */
    background-color: #f304cb11;
    
    padding: 1rem;
    width: 100%;
    display: grid;
    grid-template: auto auto/180px 1fr 1fr;
    gap: 1rem;
}

.detail-card .slide {
    width: 100%;
    height: 100%;
}

.item-avatar {
    margin-top: 7px;
    padding: 3px;
    aspect-ratio: 4/5;
    grid-row: span 2;
    background-color: #ffffff11;
    position: relative;
    overflow: hidden;

}

.item-avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-meta-details {
    grid-column: span 2;
    position: relative;
}


.item-meta-details > h2,.item-meta-details > h4, .rating {
    max-width: 400px;
    min-height: 30px;
    position: relative;
}

.item-meta-details > p {
    min-height: 24px;
    position: relative;
    overflow: hidden;
}

.item-description {
    grid-column: span 2;
    text-align: justify;
}

.item-category-contianer {
    margin-top: 1rem;
    width: 100%;
    height: fit-content;
    display: flex;
    overflow: scroll;
}

.item-category-contianer .item-category {
    display: block;
    padding: 0.5rem .8rem;
    margin-right: .5rem;
    text-wrap: nowrap;
    font-size: .9rem;
    border-radius: 20px;
}


@media screen and (max-width: 760px) {
    .detail-card {
        grid-template: auto auto/160px 1fr 1fr;
    }
    .item-meta-details {
        grid-row: span 2;
    }

    .item-description {
        grid-column: span 3;
    }

    #carousel .item-description {
        display: none;
    }

    
    #carousel .item-name {
        font-size: 1rem;
    }
}