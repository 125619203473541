#footer {
    width: 100%;   
    border-top: 1px solid #fff;
}

.menu-container {
    padding: 2rem;
    width: 60%;
    justify-content: space-around;

}

li {
    list-style: none;
}

li > a {
    color: inherit;
    text-decoration: none;
    position: relative;
    justify-content: center;
    transition: .5s;
}

li > a::after {
    content: '';
    width: 0%;
    height: 1px;
    background-color: #f304d3;
    transform-origin: center;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: .5s;
}

li > a:hover {
    color: #f304d3;
}

li > a:hover::after {
    width: 100%;
    transition: .5s;
}

.logo-container {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container img {
    width: 100%;
}

#footer p {
    /* padding: .5rem 0rem; */
    text-align: center;
}

@media screen and (max-width: 900px ) {
    #footer {
        border-top: 1px solid #fff;
    }
    .menu-container {
        width: 100%;
    }
    #footer > .container {
        flex-direction: column;
    }   

    .logo-container {
        width: 100%;
    }
    
    .logo-container img {
        width: 200px;
    }
}