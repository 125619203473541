* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #030F22;
    scroll-behavior: smooth;
    color: #cb9ce0;
    width: 100%;
}

#root {
    margin: auto;
    width: 900px;
    margin-bottom: 3rem;
}

.logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.logo h2 {
    color: #030F22;
    font-size: 2rem;
    -webkit-text-stroke: #f9f9f9 1px;
    text-shadow: 0px 2px 50px #f304d3;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.logo .logo-icon {
    margin: 0px 5px;
    color: #f304cb99;
    font-size: 1.5rem;
}

.logo img {
    height: 50px;
}

.container {
    padding: 1rem;
    /* height: fit-content; */
    position: relative;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.p-0 {
    padding: 0rem !important;
}

.main-box-shadow {
    border: 1px solid #f9f9f95b;
    box-shadow: 0px 1px 10px #f2f2f267;
}


::-webkit-scrollbar {
  width: 1px !important;
  height: 0px !important;
}

::-webkit-scrollbar-thumb        { 
  width: 0px !important;
  height: 0px !important;
}

.skeliton {
    color: #ffffff00;
    font-weight: lighter;
    margin: 5px 0px;
    background-color: #ffffff11 ;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    animation: fade-in-out 2s ease-in-out 0s infinite;
}

.btn {
    padding: .5rem 1rem;
    outline: none;
    border: none;
    text-decoration: none;
    background-color: #f304cb;
    color: #f9f9f9;
    cursor: pointer;
}

.btn-outline {
    background-color: transparent;
    border: #f304cb solid 1px;
    transition: .5s;
}

.btn-outline:hover {
    background-color: #f304cb   ;
}

.float-top-right {
    position: absolute;
    right: 0;
}

.mr-1 {
    margin-right: 1rem;
}


@keyframes skeliton-animation {
    0% {
        left: -10%;
    }
    100%
    {
        left: 110%;
    }
}

@keyframes fade-in-out {
    0% {
        /* color: #ffffff11; */
        background-color: #ffffff11;
    }
    50% {
        /* color: #ffffff3d; */
        background-color: #ffffff3d;
    }

    100% {
        /* color: #ffffff11; */
        background-color: #ffffff11;
    }
}

@media screen and (max-width: 900px) {
    #root {
        width: 100vw;
    }
}

@media screen and (max-width: 760px) {
    .container {
        padding: 0rem .5rem;
    }
}