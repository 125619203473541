.player {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #060606;
}

.player .play-icon {
    font-size: 2.5rem;
    cursor: pointer;
}

.comments-container {
    margin: 2rem 0rem;
}

.new-comment-container {
    margin: 2rem 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex: 1; */
}

.input-group {
    margin: .5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.input-group > input {
    padding: 0.5rem 1rem;
    background-color: #ffffff3d;
    outline: none;
    border: 1px solid #f304cb;
    color: #f9f9f9;
}

.input-group > textarea {
    padding: 0.5rem 1rem;
    background-color: #ffffff3d;
    outline: none;
    border: #f304cb 1px solid;
    color: #f9f9f9;
}

.new-comment-container button {
    margin-top: 1rem;
}

iframe {
    border:  none;
}

@media screen and (max-width: 760px) {
    .player {
        height: 250px;
    }
}