.slider-main {
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.slider-container {
    margin-bottom: 1rem;
    padding: 0rem 0.5rem;
    max-width: 100%;
    height: fit-content;
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
    position: relative;
    background-color: #f304cb11;
}

.slider-wrapper {
    padding: 0.5rem;
    height: 100%;
    display: flex;
    gap: 10px;
    /* overflow: scroll;    */
}

.arrow {
    height: 94%;
    font-size: 2rem;
    background-color: #00000047;
    cursor: pointer;
    align-items: center;
    position: absolute;
    top: -10px;
    opacity: 0.2;
    user-select: none;
    transition: .5s;
    z-index: 9;
}

.right {
    right: 0;
}

.left {
    left: 0;
}

.arrow:hover {
    opacity: 1;
}

