#header {
    width: 100%;
}

.nav-bar {
    width: 100%;    
    border: 1px solid #7c7c7c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.navbar-container {
    display: flex;
    list-style: none;
}

.navbar-item {
    padding: .5rem 1rem;
}

.navbar-link {
    color: inherit;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.navbar-link:hover, .navbar-link.active  {
    color: #f304cb;
}

.navbar-link::after {
    content: '';
    width: 0%;
    height: 1px;
    background-color: #f304d3;
    transform-origin: center;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: .5s;
}

.active-link {
    color: #f304cb;
    width: 100%;
}

.navbar-link:hover::after, .navbar-link.active::after {
    width: 100%;
    transition: .5s;
}

.search-bar {
    height: 100%;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.search-input {
    padding: 0.5rem 1rem;
    width: 300px;
    height: 100%;
    background-color: #bbb8b8;
    color: #030F22;
    outline: none;
    border: none;
}

.search-input::placeholder {
    color: #f304cb;
}

.link-icon {
    display: none;
    font-size: 1.5rem;
    transition: 0.5s;
}

@media screen and (max-width: 760px) {
    .nav-bar {
        flex-direction: column;
        align-items: center;
    }
    .navbar-container {
        width: 100%;
        justify-content: space-evenly;
    }
    .navbar-link {
        padding: 0.5rem 0rem;
    }

    .search-bar {
        width: 90%;
        margin-bottom: 10px;
    }

    .search-input {
        width: 100%;
    }

    .link-icon {
        display: inline-flex;
    }

    .link-icon:hover {
        color: #f304cb;
    }

    .link-title {
        display: none;
    }
}